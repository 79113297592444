import api from '../../utils/api'
export async function get_detail (payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {
  //         order_state: 1,
  //         order_state_label: '待支付',
  //         order_type: 1,
  //         payment_time: 5,
  //         shop_title: '罗湖中心医院',
  //         shop_img: '',
  //         pay_list: [
  //           {
  //             goods_title: '血清蛋白电泳',
  //             good_id: 1,
  //             goods_img: '',
  //             goods_specifications:'100',
  //             goods_specifications_unit: 'ml',
  //             goods_info: '血常规是最一般，最基本的血液检验。电话打过很多个咕咚咕咚获得更好的咕咚咕咚回个电话',
  //             price: '100',
  //             pay_type: 1,
  //             goods_num: 3
  //           },
  //           {
  //             goods_title: '血清蛋白电泳',
  //             good_id: 2,
  //             goods_img: '',
  //             goods_specifications:'100',
  //             goods_specifications_unit: 'ml',
  //             goods_info: '血常规是最一般，最基本的血液检验。',
  //             price: '100',
  //             pay_type: 2,
  //             goods_num: 3
  //           }
  //         ],
  //         medical_insurance_pay_total: 200,
  //         own_pay_total: '100',
  //         pay_total: 300,
  //         order_no: '1684828238281811818',
  //         transaction_no:'323331684828238281811818',
  //         create_time: new Date(),
  //         patient_name: '张三',
  //         patient_phone: '18788888888',
  //         address: '上海市 徐汇区瑞平路280号46号2201室'
  //       }
  //     }, 500)
  //   })
  // })
  return api.get(`/api/v1/mp/orders/order_datail`,{params:payload})
}

