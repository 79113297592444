import api from '../../utils/api'
// 登录
export async function get_order_list (payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {
  //         total_elements: 7,
  //         data: [
  //           {
  //             order_type: 3,
  //             shop_title: '益丰大药房',
  //             payment_type: 1,
  //             images: ['',''],
  //             pay_total: 20,
  //             report_id: 1,
  //             num: 2,
  //             order_state_label: '待付款',
  //             order_state: 1,
  //             order_id: 1,
  //             group_id: 1,
  //           },
  //           {
  //             order_type: 2,
  //             shop_title: '益丰大药房',
  //             payment_type: 1,
  //             images: ['',''],
  //             pay_total: 20,
  //             report_id: 1,
  //             order_title: '基因检测等24项检查',
  //             order_state_label: '待付款',
  //             order_state: 1,
  //             order_id: 122,
  //             group_id: 2,
  //             patient_name: '张三',
  //             patient_age: 34,
  //           },
  //           {
  //             order_type: 3,
  //             shop_title: '罗湖中心医院',
  //             payment_type: '罗湖中心医院',
  //             pay_way: 2,
  //             pay_total: 200,
  //             order_state: 2,
  //             order_state_label: '待发货',
  //             report_id: 10,
  //             num: 2,
  //             order_title: '基因检测等24项检查',
  //             order_id: 4,
  //             group_id: 3,
  //             reservation_day: new Date(),
  //           },
  //           {
  //             order_type: 3,
  //             shop_title: '罗湖中心医院',
  //             payment_type: 2,
  //             pay_total: 200,
  //             order_state: 3,
  //             num: 2,
  //             order_state_label: '待收货',
  //             report_id: 4,
  //             order_title: '基因检测等24项检查',
  //             order_id: 5,
  //             group_id: 4,
  //             reservation_day: new Date(),
  //           },
  //           {
  //             order_type: 3,
  //             shop_title: '罗湖中心医院',
  //             payment_type: 2,
  //             pay_total: 200,
  //             order_state: 4,
  //             order_state_label: '已完成',
  //             report_id: 4,
  //             order_title: '基因检测等24项检查',
  //             order_id: 222,
  //             group_id: 5,
  //             reservation_day: new Date(),
  //           },
  //           {
  //             order_type: 2,
  //             shop_title: '罗湖中心医院',
  //             payment_type: 3,
  //             pay_total: 200,
  //             order_state: 4,
  //             order_state_label: '已完成',
  //             report_id: 3,
  //             order_title: '基因检测等24项检查',
  //             order_id: 3,
  //             group_id: 6,
  //             patient_name: '张三',
  //             patient_age: 34,
  //             reservation_day: new Date(),
  //             reservation_state: 3,
  //             reservation_state_label: '等待报告'
  //           },
  //           {
  //             order_type: 2,
  //             shop_title: '罗湖中心医院',
  //             payment_type: 3,
  //             pay_total: 200,
  //             order_state: 3,
  //             order_state_label: '待收货',
  //             report_id: 3,
  //             order_title: '基因检测等24项检查',
  //             order_id: 88,
  //             group_id: 8,
  //             patient_name: '张三',
  //             patient_age: 34,
  //             reservation_day: new Date(),
  //             reservation_state: 1,
  //             reservation_state_label: '等待预约'
  //           },
  //           {
  //             order_type: 2,
  //             shop_title: '罗湖中心医院',
  //             payment_type: 3,
  //             pay_total: 200,
  //             order_state: 3,
  //             order_state_label: '待收货',
  //             report_id: 3,
  //             order_title: '基因检测等24项检查',
  //             order_id: 888,
  //             group_id: 9,
  //             patient_name: '张三',
  //             patient_age: 34,
  //             reservation_day: new Date(),
  //             reservation_state: 2,
  //             reservation_state_label: '等待检查',
  //           },
  //           {
  //             order_type: 1,
  //             shop_title: '罗湖中心医院',
  //             payment_type: 3,
  //             pay_total: 200,
  //             order_state: 4,
  //             order_state_label: '已完成',
  //             report_id: 3,
  //             order_title: '基因检测等24项检查',
  //             order_id: 3,
  //             group_id: 10,
  //             patient_name: '张三',
  //             patient_age: 34,
  //             reservation_day: new Date(),
  //             reservation_state: 4,
  //             reservation_state_label: '已出报告'
  //           },
  //           {
  //             order_type: 2,
  //             shop_title: '罗湖中心医院',
  //             payment_type: 3,
  //             pay_total: 200,
  //             order_state: 5,
  //             order_state_label: '已取消',
  //             report_id: 5,
  //             order_title: '基因检测等24项检查',
  //             order_id: 6,
  //             group_id: 11,
  //             patient_name: '张三',
  //             patient_age: 34,
  //             reservation_day: new Date(),
  //           }
  //         ]
  //       }
  //     })
  //   }, 500)
  // })
  return api.get('/api/v1/mp/orders', {params:payload})
}

export async function confirm_cancel_order (payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {

  //       }
  //     })
  //   }, 500)
  // })
  return api.post(`/api/v1/mp/orders/${payload}/state/cancel`)
}
