<template>
  <div class='detail' v-if="detail_data">
    <g-loading :loading="loading"/>
    <div class="head">
      <div class="bg"/>
      <div class="text1">
        {{detail_data.order_state_label}}
        <div>逾期订单将自动取消</div>
      </div>
      <div class="process" v-if="detail_data.order_state !== 5">
        <div v-for="(i,index) in hander_list(detail_data)" :key="index" :class="['item',{'item-2': index===0},{active: i.value<=detail_data.order_state}]">
          <div :class="['yuan',{'active-yuan': detail_data.order_state>=i.value}]"/>
          <div :class="['line',{'active-line': detail_data.order_state>=i.value}]"/>
          <div :class="{'label-position':index!==hander_list(detail_data).length-1&&index!==0}">{{i.label}}</div>
        </div>
      </div>
    </div>
    <div class="wait-pay" v-if="detail_data.order_state === 1">
      <div class="pay-time">剩余支付时间为 <van-count-down class="time-no" @finish="finish" :time="+detail_data.payment_time * 60 * 1000" format="mm:ss" /></div>
      <div class="pay-time-info">5分钟内未支付，订单将自动取消</div>
      <div class="foot-button">
        <div class="cancel-button" @click="cancel_order">取消订单</div>
        <div @click="to_pay">去支付</div>
      </div>
    </div>
    <div class="goods-info" v-if="detail_data.order_state === 3 && detail_data.order_type===3">
      <div class="img-bg">
        <img width="14" height="14" src="../../assets/img/order/location-icon.png"/>
      </div>
      <div class="patient-info">
        {{detail_data.patient_name}}<span>{{detail_data.patient_phone}}</span>
        <div>{{detail_data.address}}</div>
      </div>
    </div>
    <div class="content">
      <div class="title">
        <img width="22" height="22" v-if="detail_data.order_state === 3" src="../../assets/img/order/medicine.png"/>
        <img width="22" height="22" v-else src="../../assets/img/order/luohu.png"/>
        {{detail_data.shop_title}}
      </div>
      <template v-if="detail_data.order_type===3">
        <div class="list-i" v-for="(i,index) in detail_data.items" :key="index">
          <div class="i-img"></div>
          <div class="part-2 name">
            {{i.goods_title}}
            <div>{{i.goods_specifications}}{{i.goods_specifications_unit}}</div>
          </div>
          <div class="name">
            <span>{{i.pay_type === 1 ? '医保' : '自费'}}</span> ¥{{i.price}}
            <div style="text-align: right"> x{{i.count}}</div>
          </div>
        </div>
      </template>
      <template v-else>
        <div :class="['list-item',{'list-item-1': i.pay_type ===2}]" v-for="(i,index) in detail_data.items" :key="index">
          <img width="16" height="16" src="../../assets/img/order/order.png"/>
          <div class="name">
            {{i.goods_title}}
            <div>{{i.goods_info}}</div>
          </div>
          <div class="price"><span>¥ </span>{{i.price}}</div>
          <div :class="['pay-type',{'pay-type-1': i.pay_type === 2}]">{{i.pay_type === 1 ? '医保' : '自费'}}</div>
        </div>
      </template>
      <div class="pay-1" style="padding-top:13px;" >
        <div>医保支付</div>
        <span>¥ </span>{{total.no_own_total}}
      </div>
      <div class="pay-1">
        <div>自费支付</div>
        <span>¥ </span>{{total.own_total}}
      </div>
      <div class="total">总订单<span class="unit">¥ </span><span>{{total.total}}</span></div>
    </div>
    <div class="order-info">
      <div class="title">订单信息</div>
      <div>订单编号:<span>{{detail_data.id}}</span></div>
      <div>支付交易号:<span>{{detail_data.transaction_no}}</span></div>
      <div>创建时间:<span>{{format_date(detail_data.create_time,'yyyy-MM-dd HH:mm:ss')}}</span></div>
    </div>
  </div>
</template>
<script>
import {get_detail} from './service'
import {confirm_cancel_order} from '../my-order/service'
import { Dialog } from 'vant';
import {format_date} from '../../utils/format'
export default {

  data () {

    return {
      order_id: '',
      loading: false,
      detail_data: null,
      list: [{value: 1,label: '待支付'},{value: 3,label: '待收货'},{value: 4,label: '已完成'}],
      list_3: [{value: 1,label: '待支付'},{value: 2,label: '待发货'},{value: 3,label: '待收货'},{value: 4,label: '已完成'}],
    }
  },
  watch: {

  },
  computed: {
    total() {
      let total = 0
      let no_own_total = 0
      let own_total = 0
      for(let i of this.detail_data.items){
        if(i.pay_type === 1 )no_own_total += i.price
        if(i.pay_type === 2 )own_total += i.price
      }
      total = no_own_total + own_total
      return {
        total,
        no_own_total,
        own_total
      }
    }
  },
  created() {
    let {order_id} = this.$route.query
    this.order_id = order_id
    this.search_detail()
  },
  methods: {
    format_date,
    hander_list(detail_data) {
      return detail_data.order_type===3 ? this.list_3 : this.list
    },
    cancel_order() {
      const {order_id} = this.$route.query
      Dialog.alert({
        message: '是否取消订单？',
      }).then(() => {
        this.confirm_cancel_order(order_id)
      })
    },
    to_pay() {
      let {group_id} = this.$route.query
      this.$router.push({
        path: '/pre-order',
        query: {
          order_group_id: group_id,
        }
      })
    },
    finish() {
      this.search_detail()
    },
    async search_detail() {
      const {order_id} = this.$route.query
      try {
        this.loading = true
        const {data} = await get_detail({order_id: order_id})
        this.detail_data = data
      } finally  {
        this.loading = false
      }
    },
    async confirm_cancel_order(id) {
      try {
        this.loading = true
        await confirm_cancel_order(id)
        this.search_order_list()
      } finally {
        this.loading =  false
      }
    }
  }
}
</script>
<style scoped>
.detail {
  height: 100%;
  background: #F7F7F7;
}
.head {
  padding: 25px 24px 24px;
  height: 166px;
  position: relative;
  background: #000CA8;
  display: flex;
  flex-direction: column;
  & .text1 {
    flex:1;
    font-size: 24px;
    font-weight: 600;
    line-height: 34px;
    color: #FFFFFF;
    & >div {
      font-size: 12px;
      font-weight: 400;
      line-height: 17px;
      color: #FFFFFF;
    }
  }
  & .bg {
    width: 106px;
    height: 100%;
    background: url('../../assets/img/order/detail-bg.png') center center no-repeat;
    background-size: 100%;
    position: absolute;
    right: 0;
    top: 0;
  }
  & .process {
    position: relative;
    display: flex;
    font-size: 12px;
    font-weight: 500;
    line-height: 17px;
    margin-left: 15px;
    & .item {
      position: relative;
      flex:1;
      color: #fff;
      text-align: right;

      & .label-position {
        display: inline-block;
        transform: translateX(25%)
      }
      & .yuan {
        position: absolute;
        top: -15px;
        right: 0px;
        z-index: 1;
        transform: translateY(-50%);
        width: 15px;
        height: 15px;
        background-color: #fff;
        border-radius: 50%;
        &.active-yuan {
          background-color: rgba(255, 230, 35, 1);
        }
      }
      & .line {
        position: absolute;
        top: -15px;
        right: 0px;
        width: 100%;
        height: 2px;
        background-color:rgba(139, 149, 158, 1);
        &.active-line {
          background-color:rgba(255, 230, 35, 1);
        }
      }
      &.item-2{
        position: absolute;
        left: -15px;
       & .yuan {
          right: auto;
          left: 0;
       }
      }
      &.active{
        color: rgba(255, 230, 35, 1);
      }
    }
  }
}
.wait-pay {
  padding: 23px 26px;
  margin-bottom: 12px;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  color: #707070;
  background: #FFFFFF;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.04);
  & .pay-time {
    display: flex;
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    color: rgba(61, 61, 61, 1);
    & .time-no {
      font-size: 18px;
      font-weight: 500;
      line-height: 25px;
      color: rgba(255, 120, 35, 1);
      padding-left: 10px;
      padding-top: 2px;
    }
  }
  & .foot-button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-top: 1px solid rgba(112, 112, 112, 0.16);
    padding-top: 11px;
    margin-top: 16px;
    & > div {
      width: 70px;
      height: 28px;
      background: #0088FF;
      border-radius: 20px;
      font-size: 12px;
      font-weight: 400;
      line-height: 7px;
      color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 9px;
      &.cancel-button {
        color: #4E4E4E;
        background: #FFFFFF;
        border: 1px solid #B5B5B5;
      }
    }
  }
}
.goods-info {
  padding: 20px 24px;
  margin-bottom: 17px;
  display: flex;
  background: #FFFFFF;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.04);
  & .img-bg {
    width: 29px;
    height: 29px;
    background: linear-gradient(137deg, #00BAFF 0%, #4EAEFF 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius:50%;
    margin-right: 15px;
  }
  & .patient-info {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    color: #3D3D3D;
    & span {
      font-size: 14px;
    }
    & >div {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #707070;
    }
  }
}
.content {
  padding: 20px 24px;
  background: #FFFFFF;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.04);
  margin-bottom: 12px;
  & .title {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #3D3D3D;
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    & img {
      margin-right: 8px;
    }
  }
  & .list-i {
    display: flex;
    align-items: center;
    margin-bottom: 17px;
    & .i-img {
      width: 57px;
      height: 57px;
      background: RGBA(242, 242, 242, 1);
      margin-right: 16px;
    }
    & .part-2 {
      flex: 1;
    }
    & .name{
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: #242938;
      & >div{
        font-size: 10px;
        font-weight: 400;
        line-height: 16px;
        color: #454545;
        padding-top: 1px;
      }
      & >span {
        font-size: 12px;
      }
    }

  }
  & .list-item {
    display: flex;
    align-items: center;
    height: 65px;
    background: #F4F9FE;
    margin-bottom: 12px;
    padding: 0 30px 0 16px;
    border-radius: 10px;
    position: relative;
    & .pay-type{
      font-size: 9px;
      font-weight: 400;
      line-height: 13px;
      color: #FFFFFF;
      position: absolute;
      top: 0;
      right: 0;
      width: 37px;
      height: 25px;
      background-image: url("../../assets/img/order/no-own.png");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
     &.pay-type-1 {
        background-image: url("../../assets/img/order/own.png");
     }
    }
    &.list-item-1 {
      background: #F5FDF5;
    }
    & .name {
      flex: 1;
      padding:0 27px 0 12px;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: #242938;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space:nowrap;
      & > div {
        font-size: 10px;
        font-weight: 400;
        line-height: 14px;
        color: #454545;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space:nowrap
      }
    }
    & .price {
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: #242938;
      & >span {
        font-size: 12px;
      }
    }
  }
  & .pay-1 {
    display: flex;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: #565656;
    padding-bottom: 6px;
    & >div {
      flex: 1;
    }
    & span {
      font-size: 12px;
    }
  }
  & .total {
    text-align: right;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    color: #565656;
    padding-top: 16px;
    & span {
      font-size: 21px;
      font-weight: 500;
      line-height: 16px;
      color: #FF7823;
      &.unit {
        font-size: 12px;
        padding-left: 5px;
      }
    }
  }
}
.order-info {
  padding: 20px 24px;
  background: #FFFFFF;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.04);
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: #565656;
  & >div {
    display: flex;
    padding-bottom: 6px;
    & span {
      flex:1;
      text-align: right;
    }
  }
  & .title {
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
    color: #3D3D3D;
    padding-bottom: 16px;
  }
}

</style>
